<div 
    class="app-avatar {{joinedClasses()}}"
    [style.width]="size + 'px'"
    [style.height]="size + 'px'"
    [style.font-size]="fontSize + 'px'"
    [style.background-color]="backgroundColor()"
    [style.color]="color()"
    [style.border-color]="borderColor()"
    [style.border-width]="borderWidthComputed() + 'px'"
    >
    @if(!image()){ <span>{{initials() ?? computedInitials()}}</span> }
    @if(image()){ <img [src]="image()" [alt]="initials() ?? computedInitials()"> }
</div>